import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20px'
};

const titleStyle = {
    color: '#333',
    textAlign: 'center'
};

const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer'
};

const buttonMainStyle = {
    ...buttonStyle,
    marginLeft: '10px'
};

const certificateStyle = {
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#fff',
    width: '100%'
};

const noCertificatesStyle = {
    color: '#FF0000',
    fontWeight: 'bold'
};

const messageStyle = {
    marginTop: '20px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid',
    width: '100%',
    textAlign: 'center'
};

const successMessageStyle = {
    ...messageStyle,
    borderColor: 'green',
    color: 'green'
};

const errorMessageStyle = {
    ...messageStyle,
    borderColor: 'red',
    color: 'red'
};

const Certificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCertificates = async () => {
            const token = localStorage.getItem('kc_token');
            const response = await fetch(`${API_BASE_URL}/cpo_connection/cert/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const data = await response.json();
            if (response.ok) {
                setCertificates(data);
            } else if (response.status === 401) {
                console.error('Unauthorized access. User must log in again.');
                setMessage({ type: 'error', text: 'Session expired. Please log in again.' });
            } else {
                console.error('Failed to fetch certificates:', data);
                setMessage({ type: 'error', text: 'Failed to fetch certificates' });
            }
            setLoading(false);
        };

        fetchCertificates();
    }, []);

    const handlePostCertificate = async (certType, cert) => {
        const token = localStorage.getItem('kc_token');
        const certificateData = {
            cert_type: certType,
            cert: cert
        };

        const response = await fetch(`${API_BASE_URL}/cpo_connection/cert`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(certificateData)
        });

        const data = await response.json();

        if (response.ok) {
            setCertificates([...certificates, certificateData]);
            setMessage({ type: 'success', text: `Certificates posted successfully` });
        } else if (response.status === 401) {
            console.error(`Unauthorized access while posting certificate ${certType}. User must log in again.`);
            setMessage({ type: 'error', text: 'Session expired. Please log in again.' });
        } else {
            console.error(`Failed to post certificate ${certType}:`, data);
            setMessage({ type: 'error', text: `Failed to post certificate ${certType}` });
        }
    };

    const handlePostBothCertificates = async () => {
        const cert = "-----BEGIN CERTIFICATE-----\nMIIDlDCCAnygAwIBAgIINmaCmH3qPRUwDQYJKoZIhvcNAQELBQAwgYkxCzAJBgNV\nBAYTAkRFMRswGQYDVQQIExJOb3JkaGVpbi1XZXN0ZmFsZW4xDjAMBgNVBAcTBUVz\nc2VuMRYwFAYDVQQKEw1EaWdpa29vIENvcnAuMRIwEAYDVQQLEwlTb2x1dGlvbnMx\nITAfBgNVBAMTGGRjaC1zdGcuZGlnaWtvby5JbmdhLmNvbTAeFw0yNDA3MTgxMzUw\nMDZaFw0yNTA3MTgxMzUwMDZaMIGJMQswCQYDVQQGEwJERTEbMBkGA1UECBMSTm9y\nZGhlaW4tV2VzdGZhbGVuMQ4wDAYDVQQHEwVFc3NlbjEWMBQGA1UEChMNRGlnaWtv\nbyBDb3JwLjESMBAGA1UECxMJU29sdXRpb25zMSEwHwYDVQQDExhkY2gtc3RnLmRp\nZ2lrb28uSW5nYS5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCY\n4ZIHY3G/v+J2sCvVmybhGDvYbTQCtqVFfJ8hIqu1+OkU5R/0ZWKAmMWu1WyCcrsi\nWZU7eJYKHg5kwXPS86/ihds9xanFNjyDamqXwSsf02I71L/KPQm82Uhxy5qU5xLM\nAwff4qNDmZAZIWKwe6MEsJIEtxznrdlknaKOaSt4GglaSn0/dqVdwyXtutsPFlCW\nCgail7Zp/EbPyTAqYil31oZZcClErvv15wx3bEYN81d8op3hNDm3QB0T1mLJvrZE\nbATM0HiZkexwuKVZAtpT24KshJtz4/YufVFRfNd7NBXu++6EAjFltDlH+DTOSam8\nyGI7NWTQa9E1eumgluBNAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAA9b4HLGM/7p\nuOtfuHPnnWMfzRtUZ4mLltsL3ySCN/qs1/tnGur/+S8oTWE7Hug5xgZL+wzDwt8V\nSWzFQ7YKx/rpCBJ4nyhfkBDdiD1pt5B5TnIpSD/ITYinXRRkoTX2buxJYHHOysMD\nHlJ9tsbCIPee2bSmxCln84o01U38BhL7zie5cQtHGIKu2zfwF6Rj7ueinVlbZB7X\n4SpRmH+HrkBWppbXFNmPUR/BFaFdUC+EM+jByRgU44RAxpDyxW7u8NmEceyuHVUL\nIBnXvBBcYt2tjZU2T8vGrffWWQLUBatDPkvuSvEkHO7OM5yFW7Zx0ClV7OEPpyh4\n9StxyWt4+fc=\n-----END CERTIFICATE-----";

        await handlePostCertificate("TLS_Server", cert);
        await handlePostCertificate("TLS_Client", cert);
    };

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <h1 style={titleStyle}>Certificates</h1>
                <button style={buttonMainStyle} onClick={() => navigate('/')}>Main Page</button>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : certificates.length > 0 ? (
                certificates.map((cert, index) => (
                    <div key={index} style={certificateStyle}>
                        <p><strong>Type:</strong> {cert.cert_type}</p>
                        <p><strong>Certificate:</strong> {cert.cert}</p>
                    </div>
                ))
            ) : (
                <div>
                    <p style={noCertificatesStyle}>No certificates found</p>
                    <button style={buttonStyle} onClick={handlePostBothCertificates}>Post Both Certificates</button>
                </div>
            )}
            {message && (
                <div style={message.type === 'success' ? successMessageStyle : errorMessageStyle}>
                    {message.text}
                </div>
            )}
        </div>
    );
};

export default Certificates;
