// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.button {
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    text-align: center;
    display: block;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 16px;
    cursor: pointer;
}

.button:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-full-width {
    grid-column: 1 / -1; /* Span across all columns */
}

.button-red {
    background-color: #f44336; /* Red */
}

.button-red:hover {
    background-color: #e53935; /* Darker red */
}

.button-green {
    background-color: #4CAF50; /* Green */
}

.button-green:hover {
    background-color: #45a049; /* Darker green */
}
`, "",{"version":3,"sources":["webpack://./src/Components/MainPage.css"],"names":[],"mappings":";;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,kDAAkD;IAClD,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,mBAAmB,EAAE,4BAA4B;AACrD;;AAEA;IACI,yBAAyB,EAAE,QAAQ;AACvC;;AAEA;IACI,yBAAyB,EAAE,eAAe;AAC9C;;AAEA;IACI,yBAAyB,EAAE,UAAU;AACzC;;AAEA;IACI,yBAAyB,EAAE,iBAAiB;AAChD","sourcesContent":["\n\n.button {\n    background-color: #4CAF50;\n    color: white;\n    text-decoration: none;\n    text-align: center;\n    display: block;\n    padding: 10px 20px;\n    border-radius: 5px;\n    transition: background-color 0.3s, box-shadow 0.3s;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.button:hover {\n    background-color: #45a049;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.button-full-width {\n    grid-column: 1 / -1; /* Span across all columns */\n}\n\n.button-red {\n    background-color: #f44336; /* Red */\n}\n\n.button-red:hover {\n    background-color: #e53935; /* Darker red */\n}\n\n.button-green {\n    background-color: #4CAF50; /* Green */\n}\n\n.button-green:hover {\n    background-color: #45a049; /* Darker green */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
