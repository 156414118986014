// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.btn {
    padding: 10px;
    font-size: 16px;
    background-color: #007BFF;
    color: #FFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
    width: 100%;
}

.btn:hover {
    background-color: #0056b3;
}

.message {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    background-color: #d4edda;
    color: #155724;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SendCreatedEvent.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;IACjC,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".form-container {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    background-color: #f9f9f9;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\nh2 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.form {\n    display: flex;\n    flex-direction: column;\n}\n\n.form-group {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 15px;\n}\n\n.form-label {\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.form-control {\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.btn {\n    padding: 10px;\n    font-size: 16px;\n    background-color: #007BFF;\n    color: #FFF;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    align-self: center;\n    width: 100%;\n}\n\n.btn:hover {\n    background-color: #0056b3;\n}\n\n.message {\n    margin-top: 20px;\n    font-size: 16px;\n    padding: 10px;\n    border-radius: 5px;\n    background-color: #d4edda;\n    color: #155724;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
