import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './MainPage.css';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const titleStyle = {
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center'
};

const buttonContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box'
};

const MainPage = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear tokens from local storage
        localStorage.removeItem('kc_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('refresh_token');
        
        // Redirect to the home page ('/') and refresh
        navigate('/', { replace: true });
        window.location.reload();  // Refresh the page
    };

    return (
        <div style={containerStyle}>
            <h1 style={titleStyle}>Welcome to the CPO Aggregator</h1>
            <div style={buttonContainerStyle}>
                <Link to="/create-dso" className="button button-red">Create DSO</Link>
                <Link to="/create-cpo-connection" className="button button-red">Create CPO Connection</Link>
                <Link to="/create-cpo" className="button button-red">Create CPO</Link>
                <Link to="/create-metering-point" className="button button-red">Create Metering Point</Link>
                <Link to="/create-evse" className="button button-green">1- Create EVSE</Link>
                <Link to="/create-restriction" className="button button-red">Create Restriction</Link>
                <Link to="/modify-certificates" className="button button-green">0- Modify Certificates</Link>
                <Link to="/send-created-event" className="button button-green">2- Send Created Event</Link>
                <Link to="/send-oadr-update-report" className="button button-green button-full-width">3- Send OADR Update Report</Link>
                <button onClick={handleLogout} className="button button-red button-full-width">Logout</button>
            </div>
        </div>
    );
};

export default MainPage;
