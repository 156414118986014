import React, { useState, useEffect } from 'react';
import { getMaxEvseId, sendOadrUpdateReport, getLastRestrictionId, getEventIds } from '../Utils/util.js'; 

const SendOadrUpdateReport = () => {
    const [loading, setLoading] = useState(true);
    const [maxEvseId, setMaxEvseId] = useState(null);
    const [evseId, setEvseId] = useState('');
    const [eventId, setEventId] = useState('');
    const [initialDateTime, setInitialDateTime] = useState('');
    const [message, setMessage] = useState('');
    const [eventIds, setEventIds] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Add state variables for power values
    const [powerValue1, setPowerValue1] = useState('');
    const [powerValue2, setPowerValue2] = useState('');
    const [powerValue3, setPowerValue3] = useState('');
    const [powerValue4, setPowerValue4] = useState('');

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const token = localStorage.getItem('kc_token');

                const evseId = await getMaxEvseId();
                setMaxEvseId(evseId);
                setEvseId(evseId); 

                const restrictionId = await getLastRestrictionId();
                console.log('Fetched restriction ID:', restrictionId);

                const eventIds = await getEventIds(restrictionId, token);
                setEventIds(eventIds);
                console.log('Fetched event IDs:', eventIds);

                if (eventIds.length > 0) {
                    setEventId(eventIds[0]);
                }

                setLoading(false);
                console.log('Max EVSE ID:', evseId); 
            } catch (error) {
                console.error('Failed to fetch initial data:', error);
                setLoading(false);
            }
        };

        fetchInitialData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage(''); // Clear previous messages
        setIsSubmitting(true); // Disable the button

        const token = localStorage.getItem('kc_token');

        try {
            // Pass the user-entered power values to the report function
            const response = await sendOadrUpdateReport(token, evseId, eventId, initialDateTime, powerValue1, powerValue2, powerValue3, powerValue4);
            setMessage(`OADR Update Report sent successfully: ${response}`);
        } catch (error) {
            setMessage(`Failed to send OADR Update Report: ${error.message}`);
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };

    if (loading) {
        return <div>Please wait...</div>;
    }

    return (
        <div className="form-container">
            <h2>Send OADR Update Report</h2>
            <form onSubmit={handleSubmit} className="form">
                <div className="form-group">
                    <label>EVSE ID:</label>
                    <input type="number" value={evseId} onChange={(e) => setEvseId(e.target.value)} required className="form-control" />
                </div>
                <div className="form-group">
                    <label>Event ID:</label>
                    <input type="text" value={eventId} onChange={(e) => setEventId(e.target.value)} required className="form-control" />
                </div>
                <div className="form-group">
                    <label>Initial DateTime:</label>
                    <input type="datetime-local" value={initialDateTime} onChange={(e) => setInitialDateTime(e.target.value)} required className="form-control" />
                </div>
                {/* Input fields for power values */}
                <div className="form-group">
                    <label>Power Value 1:</label>
                    <input type="number" value={powerValue1} onChange={(e) => setPowerValue1(e.target.value)} required className="form-control" />
                </div>
                <div className="form-group">
                    <label>Power Value 2:</label>
                    <input type="number" value={powerValue2} onChange={(e) => setPowerValue2(e.target.value)} required className="form-control" />
                </div>
                <div className="form-group">
                    <label>Power Value 3:</label>
                    <input type="number" value={powerValue3} onChange={(e) => setPowerValue3(e.target.value)} required className="form-control" />
                </div>
                <div className="form-group">
                    <label>Power Value 4:</label>
                    <input type="number" value={powerValue4} onChange={(e) => setPowerValue4(e.target.value)} required className="form-control" />
                </div>
                <button type="submit" className="btn" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send OADR Update Report'}
                </button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default SendOadrUpdateReport;
