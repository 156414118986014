import React, { useState, useEffect } from 'react';
import { createRestriction, getMaxMeteringPointId } from '../Utils/util.js';

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const inputGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
};

const labelStyle = {
    marginBottom: '5px',
    fontWeight: 'bold'
};

const inputStyle = {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc'
};

const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer'
};

const hrStyle = {
    margin: '20px 0',
    border: 'none',
    borderTop: '1px solid #ccc'
};

const messageStyle = {
    marginTop: '20px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid',
    width: '100%',
    textAlign: 'center'
};

const successMessageStyle = {
    ...messageStyle,
    borderColor: 'green',
    color: 'green'
};

const errorMessageStyle = {
    ...messageStyle,
    borderColor: 'red',
    color: 'red'
};

const CreateRestriction = () => {
    const [mpId, setMpId] = useState('');
    const [restrictionData, setRestrictionData] = useState({
        problem_id: '',
        name: '',
        person: '',
        start_date: '',
        end_date: '',
        category: '',
        status: '',
        power: '',
        start_time: '',
        end_time: ''
    });
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const fetchMaxMeteringPointId = async () => {
            try {
                const maxId = await getMaxMeteringPointId();
                setMpId(maxId);
            } catch (error) {
                console.error(error);
            }
        };

        fetchMaxMeteringPointId();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRestrictionData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await createRestriction(mpId, restrictionData);
            setMessage(result);
        } catch (error) {
            setMessage({ success: false, message: 'Failed to create Restriction' });
        }
    };

    return (
        <div>
            <h2>Create Restriction</h2>
            <hr style={hrStyle} />
            <form onSubmit={handleSubmit} style={formStyle}>
                {Object.keys(restrictionData).map(key => (
                    key !== 'power' && key !== 'start_time' && key !== 'end_time' ? (
                        <div key={key} style={inputGroupStyle}>
                            <label style={labelStyle}>{key.replace('_', ' ').toUpperCase()}:</label>
                            <input type="text" name={key} value={restrictionData[key]} onChange={handleChange} required style={inputStyle} />
                        </div>
                    ) : null
                ))}
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Power:</label>
                    <input type="number" step="0.1" name="power" value={restrictionData.power} onChange={handleChange} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Start Time:</label>
                    <input type="time" name="start_time" value={restrictionData.start_time} onChange={handleChange} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>End Time:</label>
                    <input type="time" name="end_time" value={restrictionData.end_time} onChange={handleChange} required style={inputStyle} />
                </div>
                <button type="submit" style={buttonStyle}>Create Restriction</button>
            </form>
            {message && (
                <div style={message.success ? successMessageStyle : errorMessageStyle}>
                    {message.message}
                </div>
            )}
        </div>
    );
};

export default CreateRestriction;
