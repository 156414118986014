import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import MainPage from './Components/MainPage.js';
import CreateDso from './Components/CreateDSO.js';
import CreateCpoConnection from './Components/CreateCpoConnection.js';
import CreateCpo from './Components/CreateCpo.js';
import CreateMeteringPoint from './Components/CreateMeteringPoint.js';
import CreateEvse from './Components/CreateEvse.js';
import CreateRestriction from './Components/CreateRestriction.js';
import SendCreatedEvent from './Components/SendCreatedEvent.js';
import SendOadrUpdateReport from './Components/SendOadrUpdateReport.js';
import Logout from './Components/Logout.js';
import Certificates from './Components/Certificates.js';
import TestCreatedEvent from './Components/TestCreatedEvent.js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  const keycloak = useRef(null);

  // Function to validate token using Keycloak's /userinfo endpoint
  const validateToken = async (token) => {
    const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
    const REALM = process.env.REACT_APP_REALM;
    const url = `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/userinfo`;

    console.log('Validating token with /userinfo endpoint:', url);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const userInfo = await response.json(); // Parse the response to JSON
        console.log('Token is valid. User information fetched successfully:', userInfo); // Log the user info
        return true;
      } else {
        console.error('Token validation failed:', response.status, response.statusText);
        return false;
      }
    } catch (error) {
      console.error('Error occurred during token validation:', error);
      return false;
    }
  };

  // Function to initialize Keycloak
  const initializeKeycloak = () => {
    if (!keycloak.current) {
      keycloak.current = new Keycloak({
        url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
        realm: `${process.env.REACT_APP_REALM}`,
        clientId: `${process.env.REACT_APP_CLIENT_ID}`,
      });

      keycloak.current.init({ onLoad: 'login-required' }).then(authenticated => {
        if (authenticated) {
          const token = keycloak.current.token;
          console.log('User authenticated through Keycloak. Storing tokens...');
          localStorage.setItem('kc_token', token);
          localStorage.setItem('id_token', keycloak.current.idToken);
          localStorage.setItem('refresh_token', keycloak.current.refreshToken);
          setIsAuthenticated(true);
        } else {
          console.log('User is not authenticated. Redirecting to login...');
          keycloak.current.login();
        }
        setKeycloakInitialized(true);
      }).catch(() => {
        console.log('Failed to initialize Keycloak.');
        setKeycloakInitialized(true);
      });
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('kc_token');
      console.log('Checking for stored token:');

      if (token) {
        console.log('Token found in local storage. Validating token...');
        const isValid = await validateToken(token);

        if (isValid) {
          console.log('Token is valid. User is authenticated.');
          setIsAuthenticated(true);
          setKeycloakInitialized(true);
        } else {
          console.log('Token is invalid or expired. Clearing storage and initializing Keycloak...');
          localStorage.removeItem('kc_token');
          localStorage.removeItem('id_token');
          localStorage.removeItem('refresh_token');
          initializeKeycloak();  // Re-initialize Keycloak if the token is invalid
        }
      } else {
        console.log('No token found in local storage. Initializing Keycloak...');
        initializeKeycloak(); // Initialize Keycloak if there is no token
      }
    };

    checkToken();
  }, []);

  const refreshToken = async () => {
    const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
    const REALM = process.env.REACT_APP_REALM;
    const refreshTokenValue = localStorage.getItem('refresh_token');
    const url = `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/token`;
    console.log('Refreshing token using endpoint:', url);
    const payload = `grant_type=refresh_token&client_id=${encodeURIComponent('inga_app')}&refresh_token=${encodeURIComponent(refreshTokenValue)}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: payload
      });
      const data = await response.json();
      if (data.access_token) {
        console.log('Token refreshed successfully.');
        localStorage.setItem('kc_token', data.access_token);
        localStorage.setItem('id_token', data.id_token);
        localStorage.setItem('refresh_token', data.refresh_token);
      } else {
        console.error('Failed to refresh token: No access token returned.');
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 4 * 60 * 1000); // Refresh token every 4 minutes
    return () => clearInterval(interval);
  }, []);

  if (!keycloakInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<MainPage />} />
            <Route path="/create-dso" element={<CreateDso />} />
            <Route path="/create-cpo-connection" element={<CreateCpoConnection />} />
            <Route path="/create-cpo" element={<CreateCpo />} />
            <Route path="/create-metering-point" element={<CreateMeteringPoint />} />
            <Route path="/create-evse" element={<CreateEvse />} />
            <Route path="/create-restriction" element={<CreateRestriction />} />
            <Route path="/send-created-event" element={<SendCreatedEvent />} />
            <Route path="/send-oadr-update-report" element={<SendOadrUpdateReport />} />
            <Route path="/modify-certificates" element={<Certificates />} />
            <Route path="/test-created-event" element={<TestCreatedEvent />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
