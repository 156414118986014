import React, { useState } from 'react';

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const inputGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
};

const labelStyle = {
    marginBottom: '5px',
    fontWeight: 'bold'
};

const inputStyle = {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc'
};

const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
    disabledCursor: 'not-allowed'
};

const hrStyle = {
    margin: '20px 0',
    border: 'none',
    borderTop: '1px solid #ccc'
};

const messageStyle = {
    margin: '10px 0',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center'
};

const successStyle = {
    ...messageStyle,
    backgroundColor: '#dff0d8',
    color: '#3c763d'
};

const errorStyle = {
    ...messageStyle,
    backgroundColor: '#f2dede',
    color: '#a94442'
};

function CreateEvse() {
    const [evseId, setEvseId] = useState('');
    const [maxPower, setMaxPower] = useState('');
    const [mpId, setMpId] = useState('');
    const [contractInfo, setContractInfo] = useState('');
    const [startTime, setStartTime] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const evseData = {
            evse_id_by_cpo: evseId,
            max_power_kw: parseFloat(maxPower),
            mp_id_by_dso: mpId,
            contract_information: contractInfo,
            start_time: new Date(startTime).toISOString(),
        };

        const token = localStorage.getItem('kc_token');
        try {
            const response = await fetch(`${API_BASE_URL}/evse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(evseData)
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('EVSE created successfully');
                setErrorMessage('');
            } else {
                setErrorMessage(`Failed to create EVSE: ${data.message || 'Unknown error'}`);
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage(`Failed to create EVSE: ${error.message}`);
            setSuccessMessage('');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <h2>Create EVSE</h2>
            <hr style={hrStyle} />
            <form onSubmit={handleSubmit} style={formStyle}>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>EVSE ID by CPO:</label>
                    <input type="text" value={evseId} onChange={(e) => setEvseId(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Max Power (kW):</label>
                    <input type="number" step="0.1" value={maxPower} onChange={(e) => setMaxPower(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>MP ID by DSO:</label>
                    <input type="text" value={mpId} onChange={(e) => setMpId(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Contract Information:</label>
                    <input type="text" value={contractInfo} onChange={(e) => setContractInfo(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Start Time:</label>
                    <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} required style={inputStyle} />
                </div>
                <button type="submit" style={buttonStyle} disabled={isSubmitting}>
                    {isSubmitting ? 'Creating...' : 'Create EVSE'}
                </button>
                {successMessage && <div style={successStyle}>{successMessage}</div>}
                {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
            </form>
        </div>
    );
}

export default CreateEvse;
