import React, { useState, useEffect } from 'react';
import './SendCreatedEvent.css';
import { getLastRestrictionId, getEventIds } from '../Utils/util.js';

const SendCreatedEvent = () => {
    const [venId, setVenId] = useState('');
    const [eventId, setEventId] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchEventIds = async () => {
            const token = localStorage.getItem('kc_token');

            try {
                const restrictionId = await getLastRestrictionId();
                console.log('Fetched restriction ID:', restrictionId);
                const eventIds = await getEventIds(restrictionId, token);
                console.log('Fetched event IDs:', eventIds);
                setLoading(false);  // Set loading to false after fetching
            } catch (error) {
                setMessage(error.message);
                setLoading(false);  // Set loading to false if an error occurs
            }
        };

        fetchEventIds();
    }, []);

    const sendCreatedEvent = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsSubmitting(true);  // Disable the button

        const token = localStorage.getItem('kc_token');
        const requestId = new Date().toISOString(); 
        const modificationId = 0;
        const optIn = true;

        const xmlPayload = `<?xml version="1.0" encoding="UTF-8"?>
        <ns7:oadrPayload xmlns="http://www.w3.org/2000/09/xmldsig#"
          xmlns:ns10="http://www.opengis.net/gml/3.2"
          xmlns:ns11="http://docs.oasis-open.org/ns/emix/2011/06/siscale"
          xmlns:ns12="http://www.w3.org/2009/xmldsig11#"
          xmlns:ns13="urn:un:unece:uncefact:codelist:standard:5:ISO42173A:2010-04-07"
          xmlns:ns2="http://docs.oasis-open.org/ns/energyinterop/201110"
          xmlns:ns3="http://docs.oasis-open.org/ns/energyinterop/201110/payloads"
          xmlns:ns4="http://docs.oasis-open.org/ns/emix/2011/06"
          xmlns:ns5="urn:ietf:params:xml:ns:icalendar-2.0"
          xmlns:ns6="urn:ietf:params:xml:ns:icalendar-2.0:stream"
          xmlns:ns7="http://openadr.org/oadr-2.0b/2012/07"
          xmlns:ns8="http://www.w3.org/2005/Atom" xmlns:ns9="http://docs.oasis-open.org/ns/emix/2011/06/power">
          <ns7:oadrSignedObject>
            <ns7:oadrCreatedEvent ns2:schemaVersion="2.0b">
              <ns3:eiCreatedEvent>
                <ns2:eiResponse>
                  <ns2:responseCode>200</ns2:responseCode>
                  <ns2:responseDescription>Response Description</ns2:responseDescription>
                  <ns3:requestID/>
                </ns2:eiResponse>
                <ns2:eventResponses>
                  <ns2:eventResponse>
                    <ns2:responseCode>200</ns2:responseCode>
                    <ns3:requestID>1001</ns3:requestID>
                    <ns2:qualifiedEventID>
                      <ns2:eventID>${eventId}</ns2:eventID>
                      <ns2:modificationNumber>${modificationId}</ns2:modificationNumber>
                    </ns2:qualifiedEventID>
                    <ns2:optType>${optIn ? "optIn" : "optOut"}</ns2:optType>
                  </ns2:eventResponse>
                </ns2:eventResponses>
                <ns2:venID>${venId}</ns2:venID>
              </ns3:eiCreatedEvent>
            </ns7:oadrCreatedEvent>
          </ns7:oadrSignedObject>
        </ns7:oadrPayload>`;

        const hash = "BD4E2CAC00987AA2360F";
        const cert =  "-----BEGIN+CERTIFICATE-----%0D%0AMIIDlDCCAnygAwIBAgIINmaCmH3qPRUwDQYJKoZIhvcNAQELBQAwgYkxCzAJBgNV%0D%0ABAYTAkRFMRswGQYDVQQIExJOb3JkaGVpbi1XZXN0ZmFsZW4xDjAMBgNVBAcTBUVz%0D%0Ac2VuMRYwFAYDVQQKEw1EaWdpa29vIENvcnAuMRIwEAYDVQQLEwlTb2x1dGlvbnMx%0D%0AITAfBgNVBAMTGGRjaC1zdGcuZGlnaWtvby5JbmdhLmNvbTAeFw0yNDA3MTgxMzUw%0D%0AMDZaFw0yNTA3MTgxMzUwMDZaMIGJMQswCQYDVQQGEwJERTEbMBkGA1UECBMSTm9y%0D%0AZGhlaW4tV2VzdGZhbGVuMQ4wDAYDVQQHEwVFc3NlbjEWMBQGA1UEChMNRGlnaWtv%0D%0AbyBDb3JwLjESMBAGA1UECxMJU29sdXRpb25zMSEwHwYDVQQDExhkY2gtc3RnLmRp%0D%0AZ2lrb28uSW5nYS5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCY%0D%0A4ZIHY3G%2Fv%2BJ2sCvVmybhGDvYbTQCtqVFfJ8hIqu1%2BOkU5R%2F0ZWKAmMWu1WyCcrsi%0D%0AWZU7eJYKHg5kwXPS86%2Fihds9xanFNjyDamqXwSsf02I71L%2FKPQm82Uhxy5qU5xLM%0D%0AAwff4qNDmZAZIWKwe6MEsJIEtxznrdlknaKOaSt4GglaSn0%2FdqVdwyXtutsPFlCW%0D%0ACgail7Zp%2FEbPyTAqYil31oZZcClErvv15wx3bEYN81d8op3hNDm3QB0T1mLJvrZE%0D%0AbATM0HiZkexwuKVZAtpT24KshJtz4%2FYufVFRfNd7NBXu%2B%2B6EAjFltDlH%2BDTOSam8%0D%0AyGI7NWTQa9E1eumgluBNAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAA9b4HLGM%2F7p%0D%0AuOtfuHPnnWMfzRtUZ4mLltsL3ySCN%2Fqs1%2FtnGur%2F%2BS8oTWE7Hug5xgZL%2BwzDwt8V%0D%0ASWzFQ7YKx%2FrpCBJ4nyhfkBDdiD1pt5B5TnIpSD%2FITYinXRRkoTX2buxJYHHOysMD%0D%0AHlJ9tsbCIPee2bSmxCln84o01U38BhL7zie5cQtHGIKu2zfwF6Rj7ueinVlbZB7X%0D%0A4SpRmH%2BHrkBWppbXFNmPUR%2FBFaFdUC%2BEM%2BjByRgU44RAxpDyxW7u8NmEceyuHVUL%0D%0AIBnXvBBcYt2tjZU2T8vGrffWWQLUBatDPkvuSvEkHO7OM5yFW7Zx0ClV7OEPpyh4%0D%0A9StxyWt4%2Bfc%3D%0D%0A-----END+CERTIFICATE-----";

        const headers = {
            'Content-Type': 'application/xml',
            'Authorization': `Bearer ${token}`,
            'x-forwarded-client-cert': `Hash=${hash};Cert=${cert}`
        };

        try {
            const response = await fetch('http://127.0.0.1:8080/open_adr_service/VTN/OpenADR2/Simple/2.0b/EiEvent', {
                method: 'POST',
                headers: headers,
                body: xmlPayload
            });

            if (response.ok) {
                const data = await response.text();
                setMessage(`Created Event sent successfully: ${data}`);
            } else {
                const errorText = await response.text();
                setMessage(`Failed to send Created Event: ${errorText}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);  // Re-enable the button
        }
    };

    return (
        <div className="form-container">
            {loading ? (
                <p>Please wait...</p>
            ) : (
                <form onSubmit={sendCreatedEvent} className="form">
                    <div className="form-group">
                        <label>VEN ID:</label>
                        <input type="text" value={venId} onChange={(e) => setVenId(e.target.value)} required className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Event ID:</label>
                        <input type="text" value={eventId} onChange={(e) => setEventId(e.target.value)} required className="form-control" />
                    </div>
                    <button type="submit" className="btn" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send Created Event'}
                    </button>
                </form>
            )}
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default SendCreatedEvent;
