export const getLastRestrictionId = async () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('kc_token');
    const response = await fetch(`${API_BASE_URL}/grid_restriction/by_dso?from=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    const data = await response.json();

    if (response.ok) {
        const lastRestrictionId = data[data.length - 1].restriction_id;
        console.log(`Last restriction ID: ${lastRestrictionId}`);
        return lastRestrictionId;
    } else {
        console.error('Failed to get restrictions:', data);
        throw new Error('Failed to get restrictions');
    }
};

export const createRestriction = async (meteringPointId, restrictionData) => {
    const payload = {
        problem_id: restrictionData.problem_id,
        name: restrictionData.name,
        person: restrictionData.person,
        mp_ids: [meteringPointId],
        start_date: restrictionData.start_date,
        end_date: restrictionData.end_date,
        category: restrictionData.category,
        status: restrictionData.status,
        restriction: {
            power: parseFloat(restrictionData.power),
            start_time: restrictionData.start_time,
            end_time: restrictionData.end_time
        }
    };

    console.log('Sending payload:', JSON.stringify(payload, null, 2));
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


    const token = localStorage.getItem('kc_token');
    const response = await fetch(`${API_BASE_URL}/grid_restriction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    });

    const data = await response.json();

    console.log('Response:', data);

    if (response.ok) {
        return { success: true, message: 'Restriction created successfully', data };
    } else {
        return { success: false, message: 'Failed to create Restriction', data };
    }
};

export const getMaxMeteringPointId = async () => {
    const token = localStorage.getItem('kc_token');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const response = await fetch(`${API_BASE_URL}/metering_point/by_dso?from=0`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    const data = await response.json();

    if (response.ok) {
        const meteringPointIds = data.map(mp => mp.mp_id);
        const maxMeteringPointId = Math.max(...meteringPointIds);
        console.log(`Max metering point ID: ${maxMeteringPointId}`);
        return maxMeteringPointId;
    } else {
        console.error('Failed to get Metering Points:', data);
        throw new Error('Failed to get Metering Points');
    }
};

export const getEventIds = async (restrictionId, token) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    try {
        const response = await fetch(`${API_BASE_URL}/grid_restriction/distributed_events/${restrictionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            return data.map(event => event.event_id);
        } else {
            const errorText = await response.text();
            throw new Error(`Failed to fetch event IDs: ${errorText}`);
        }
    } catch (error) {
        throw new Error(`Error fetching event IDs: ${error.message}`);
    }
};


export const getMaxEvseId = async () => {
    const token = localStorage.getItem('kc_token');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const response = await fetch(`${API_BASE_URL}/evse/by_cpo`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    const data = await response.json();

    if (response.ok) {
        const evseIds = data.map(evse => evse.evse_id);
        const maxEvseId = Math.max(...evseIds);
        console.log(`Max EVSE ID: ${maxEvseId}`);
        return maxEvseId;
    } else {
        console.error('Failed to get EVSEs:', data);
        throw new Error('Failed to get EVSEs');
    }
};

export const sendOadrUpdateReport = async (token, evseId, eventId, initialDateTime, powerValue1, powerValue2, powerValue3, powerValue4) => {
  const convertToUTC = (localDateTime) => {
      const date = new Date(localDateTime);
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
  };
  const dateTime = convertToUTC(initialDateTime);
  const intervalDuration = "PT15M"; // 15 minutes duration

  // Fetch the venId from the /cpo_connection endpoint
  let venId;
  try {
      console.log("Fetching VenId")
      const venIdResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cpo_connection`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });

      if (venIdResponse.ok) {
          const venIdData = await venIdResponse.json();
          venId = venIdData.venId;  // Extract the venId from the response
          console.log(`Fetched venId: ${venId}`)
      } else {
          const errorText = await venIdResponse.text();
          throw new Error(`Failed to fetch venId: ${errorText}`);
      }
  } catch (error) {
      console.error(`Error fetching venId: ${error.message}`);
      throw new Error(`Error fetching venId: ${error.message}`);
  }

  const oadrUpdateReportPayload = `<?xml version="1.0" encoding="UTF-8"?>
  <oadr:oadrPayload xmlns:oadr="http://openadr.org/oadr-2.0b/2012/07"
                    xmlns:pyld="http://docs.oasis-open.org/ns/energyinterop/201110/payloads"
                    xmlns:ei="http://docs.oasis-open.org/ns/energyinterop/201110"
                    xmlns:scale="http://docs.oasis-open.org/ns/emix/2011/06/siscale"
                    xmlns:emix="http://docs.oasis-open.org/ns/emix/2011/06"
                    xmlns:strm="urn:ietf:params:xml:ns:icalendar-2.0:stream"
                    xmlns:xcal="urn:ietf:params:xml:ns:icalendar-2.0"
                    xmlns:power="http://docs.oasis-open.org/ns/emix/2011/06/power">
    <oadr:oadrSignedObject>
      <oadr:oadrUpdateReport ei:schemaVersion="2.0b">
        <pyld:requestID>${eventId}</pyld:requestID>
        <oadr:oadrReport>
          <xcal:dtstart>
            <xcal:date-time>${dateTime}</xcal:date-time>
          </xcal:dtstart>
          <xcal:duration>
            <xcal:duration>PT1H</xcal:duration>
          </xcal:duration>
          <strm:intervals>
            <ei:interval>
              <xcal:dtstart>
                <xcal:date-time>${dateTime}</xcal:date-time>
              </xcal:dtstart>
              <xcal:duration>
                <xcal:duration>${intervalDuration}</xcal:duration>
              </xcal:duration>
              <oadr:oadrReportPayload>
                <ei:rID>${evseId}</ei:rID>
                <ei:payloadFloat>
                  <ei:value>${powerValue1}</ei:value> <!-- Use user-entered value -->
                </ei:payloadFloat>
                <oadr:oadrDataQuality>Quality Good - Non Specific</oadr:oadrDataQuality>
              </oadr:oadrReportPayload>
            </ei:interval>
            <ei:interval>
              <xcal:dtstart>
                <xcal:date-time>${new Date(new Date(dateTime).getTime() + 15 * 60 * 1000).toISOString()}</xcal:date-time>
              </xcal:dtstart>
              <xcal:duration>
                <xcal:duration>${intervalDuration}</xcal:duration>
              </xcal:duration>
              <oadr:oadrReportPayload>
                <ei:rID>${evseId}</ei:rID>
                <ei:payloadFloat>
                  <ei:value>${powerValue2}</ei:value> <!-- Use user-entered value -->
                </ei:payloadFloat>
                <oadr:oadrDataQuality>Quality Good - Non Specific</oadr:oadrDataQuality>
              </oadr:oadrReportPayload>
            </ei:interval>
            <ei:interval>
              <xcal:dtstart>
                <xcal:date-time>${new Date(new Date(dateTime).getTime() + 30 * 60 * 1000).toISOString()}</xcal:date-time>
              </xcal:dtstart>
              <xcal:duration>
                <xcal:duration>${intervalDuration}</xcal:duration>
              </xcal:duration>
              <oadr:oadrReportPayload>
                <ei:rID>${evseId}</ei:rID>
                <ei:payloadFloat>
                  <ei:value>${powerValue3}</ei:value> <!-- Use user-entered value -->
                </ei:payloadFloat>
                <oadr:oadrDataQuality>Quality Good - Non Specific</oadr:oadrDataQuality>
              </oadr:oadrReportPayload>
            </ei:interval>
            <ei:interval>
              <xcal:dtstart>
                <xcal:date-time>${new Date(new Date(dateTime).getTime() + 45 * 60 * 1000).toISOString()}</xcal:date-time>
              </xcal:dtstart>
              <xcal:duration>
                <xcal:duration>${intervalDuration}</xcal:duration>
              </xcal:duration>
              <oadr:oadrReportPayload>
                <ei:rID>${evseId}</ei:rID>
                <ei:payloadFloat>
                  <ei:value>${powerValue4}</ei:value> <!-- Use user-entered value -->
                </ei:payloadFloat>
                <oadr:oadrDataQuality>Quality Good - Non Specific</oadr:oadrDataQuality>
              </oadr:oadrReportPayload>
            </ei:interval>
          </strm:intervals>
          <ei:eiReportID>RP4101</ei:eiReportID>
          <ei:reportRequestID>d5f88bf0-1a8d-0132-eab3-0a5317f1edaa</ei:reportRequestID>
          <ei:reportSpecifierID>00:21:b9:00:f2:a9</ei:reportSpecifierID>
          <ei:reportName>HISTORY_USAGE</ei:reportName>
          <ei:createdDateTime>${new Date().toISOString()}</ei:createdDateTime>
        </oadr:oadrReport>
        <ei:venID>${venId}</ei:venID>
      </oadr:oadrUpdateReport>
    </oadr:oadrSignedObject>
  </oadr:oadrPayload>`;

    // const cert =  "-----BEGIN+CERTIFICATE-----%0D%0AMIIDtzCCAp%2BgAwIBAgIUVSrRQ86kX%2BuXtTM6qpYpmYc5XVAwDQYJKoZIhvcNAQEL%0D%0ABQAwgYMxCzAJBgNVBAYTAlRSMQ4wDAYDVQQIDAVpem1pcjEOMAwGA1UEBwwFaXpt%0D%0AaXIxEDAOBgNVBAoMB0RpZ2lrb28xDTALBgNVBAsMBEluZ2ExDTALBgNVBAMMBGlu%0D%0AZ2ExJDAiBgkqhkiG9w0BCQEWFWFscGFuZ29raGFuQGdtYWlsLmNvbTAeFw0yNDA3%0D%0AMjIxMzQ2MTlaFw0yNTA3MjIxMzQ2MTlaMIGDMQswCQYDVQQGEwJUUjEOMAwGA1UE%0D%0ACAwFaXptaXIxDjAMBgNVBAcMBWl6bWlyMRAwDgYDVQQKDAdEaWdpa29vMQ0wCwYD%0D%0AVQQLDARJbmdhMQ0wCwYDVQQDDARpbmdhMSQwIgYJKoZIhvcNAQkBFhVhbHBhbmdv%0D%0Aa2hhbkBnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDF%0D%0Af9ZotWqwcNwTHk1rAIhLELCA97IWxlhDCrpZaGF%2FyF1MDmSit0jBGCzA%2BVMInmjX%0D%0AKa3qk6KOqkI0S3l%2FXkDodxa7Fa7o7510OoBHZHRJDt3BdUsulM3rA%2B6z8A%2B4XX27%0D%0Aa52oMVNcC%2BEZm7iai%2BgJelhx2pt4UAJ3PK5ET%2BErR67QzilF%2FCyYsS0s6jGd2pLa%0D%0Au3jsOxuj8l5hOhZTeePI4uZD7uJ221QwH17UdMhvnZykZ7De5%2Fh%2FyAajkSv2gIAw%0D%0A6V5Uu0i8ue7Xn7gFpOK3QbQztVaUCGzgBgBweTkN3lTyCZNwUC94Kfe27I9O0XhY%0D%0A1PFYjuaHNxDKh7wQblTLAgMBAAGjITAfMB0GA1UdDgQWBBTkR9WN2Tp%2F0oOLZZNO%0D%0ATeBupIfH2jANBgkqhkiG9w0BAQsFAAOCAQEALsQxW9C1tXd0IsC2YYio8HkwnYR6%0D%0AKU7HPTI0axP3Q5xhZrrf6LQRx6Fmxpiq%2B70AG%2Fetpo5rs2XC8uBadzL6bkuh%2Fj6e%0D%0AHETG2s2ZUtnVnTedumjtqzF15Wk1%2Fa074sBiU%2BP%2BNUIu1ofoP7BSMOAKB9qqaRdP%0D%0Amir9pTFgR5ecjiE%2B7iH0QBD9Ch60GuRrxa5DIr4RvCBC0lVee%2FeVFeCcmJvcLyhr%0D%0AoFEF55gacHvC%2FNQPSkjLHZSc%2FXdKVbEcOOLe2CwNn%2FTmlGr4yCO4dQDV5MQ0zMVJ%0D%0AmBkNvSJtWjQe3Be5wPVodMTbiBtbMfZDRA%2B9lxMTarKws8hvSG6Mv7eH9g%3D%3D%0D%0A-----END+CERTIFICATE-----";
    // const hash = "BD4E2CAC00987AA2360F";
     // Fetch certificate
     let certString, hash, cert;
     try {
         certString = await getCertificate(token, "TLS_Client");
         const x509Cert = convertStringToX509Cert(certString);
         hash = fingerPrint(x509Cert).fp;
         cert = convertX509CertToMimeUrlEncodedString(x509Cert);
         console.log(cert)
         console.log(hash)
     } catch (error) {
        //  setMessage(`Failed to get certificate: ${error.message}`);
        //  setIsSubmitting(false);  // Re-enable the button
         return;
     }

    const headers = {
        'Content-Type': 'application/xml',
        'Authorization': `Bearer ${token}`,
        'x-forwarded-client-cert': `Hash=${hash};Cert=${cert}`
    };

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/open_adr_service/VTN/OpenADR2/Simple/2.0b/EiReport`, {
            method: 'POST',
            headers: headers,
            body: oadrUpdateReportPayload
        });
        console.log(oadrUpdateReportPayload)
        if (response.ok) {
            const data = await response.text();
            console.log(`OADR Update Report sent successfully: ${data}`);
            return data;
        } else {
            const errorText = await response.text();
            console.error(`Failed to send OADR Update Report: ${errorText}`);
            throw new Error(`Failed to send OADR Update Report: ${errorText}`);
        }
    } catch (error) {
        console.error(`Error: ${error.message}`);
        throw new Error(`Error: ${error.message}`);
    }
};

export const getCertificate = async (token, certType) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  try {
      const response = await fetch(`${API_BASE_URL}/cpo_connection/cert/all`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });

      if (response.ok) {
          const data = await response.json();
          const cert = data.find(cert => cert.cert_type === certType);
          if (cert) {
              return cert.cert;
          } else {
              throw new Error(`No certificate found for type: ${certType}`);
          }
      } else {
          const errorText = await response.text();
          throw new Error(`Failed to fetch certificates: ${errorText}`);
      }
  } catch (error) {
      throw new Error(`Error fetching certificates: ${error.message}`);
  }
};
const forge = require('node-forge');

export const convertStringToX509Cert = (certificateString) => {
    const cert = forge.pki.certificateFromPem(certificateString);
    return cert;
};

export const convertX509CertToMimeString = (certificate) => {
    const pem = forge.pki.certificateToPem(certificate);
    const BEGIN_CERT = '-----BEGIN CERTIFICATE-----';
    const END_CERT = '-----END CERTIFICATE-----';
    const encodedCert = pem
        .replace(BEGIN_CERT, '')
        .replace(END_CERT, '')
        .replace(/\n/g, '');
    const formattedCert = `${BEGIN_CERT}\n${encodedCert.replace(/(.{64})/g, '$1\n')}\n${END_CERT}`;
    return formattedCert;
};

export const convertX509CertToMimeUrlEncodedString = (certificate) => {
    const mimeString = convertX509CertToMimeString(certificate);
    return encodeURIComponent(mimeString);
};

export const fingerPrint = (cert) => {
    const md = forge.md.sha256.create();
    md.update(forge.asn1.toDer(forge.pki.certificateToAsn1(cert)).getBytes());
    const hash = md.digest().toHex();
    const hexString = hash.toUpperCase();
    return hexString.substring(hexString.length - 20);
};
