import React, { useEffect } from 'react';

const Logout = () => {
    const login_url = process.env.REACT_APP_LOGIN_URL + '/login'
    const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL
    const REALM= process.env.REACT_APP_REALM
    useEffect(() => {
        const thint = localStorage.getItem('id_token')
        const encodedRedirectURI = encodeURIComponent(login_url);
        const logoutUrl = `${KEYCLOAK_URL}/realms/${REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${encodedRedirectURI}&id_token_hint=${thint}`;
        localStorage.removeItem('kc_token')
        window.location.href = logoutUrl; 
    }, );

    return <div>Logging out...</div>;
};

export default Logout;
