import React, { useState, useEffect } from 'react';
import './SendCreatedEvent.css';
import { getLastRestrictionId, getEventIds, getCertificate, convertStringToX509Cert, fingerPrint, convertX509CertToMimeUrlEncodedString } from '../Utils/util.js';

const SendCreatedEvent = () => {
    const [venId, setVenId] = useState('');
    const [eventId, setEventId] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchInitialData = async () => {
            const token = localStorage.getItem('kc_token');

            try {
                // Fetch the last restriction ID
                const restrictionId = await getLastRestrictionId();
                console.log('Fetched restriction ID:', restrictionId);

                // Fetch event IDs
                const eventIds = await getEventIds(restrictionId, token);
                console.log('Fetched event IDs:', eventIds);
                
                if (eventIds.length > 0) {
                    setEventId(eventIds[0]);
                }

                // Fetch venId from /cpo_connection endpoint
                const venIdResponse = await fetch(`${API_BASE_URL}/cpo_connection`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (venIdResponse.ok) {
                    const venIdData = await venIdResponse.json();
                    setVenId(venIdData.venId); // Set venId automatically
                } else {
                    const errorText = await venIdResponse.text();
                    throw new Error(`Failed to fetch venId: ${errorText}`);
                }

                setLoading(false);  // Set loading to false after fetching
            } catch (error) {
                setMessage(error.message);
                setLoading(false);  // Set loading to false if an error occurs
            }
        };

        fetchInitialData();
    }, [API_BASE_URL]);

    const sendCreatedEvent = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsSubmitting(true);  // Disable the button

        const token = localStorage.getItem('kc_token');
        const requestId = new Date().toISOString(); 
        const modificationId = 0;
        const optIn = true;

        // Fetch certificate
        let certString, hash, cert;
        try {
            certString = await getCertificate(token, "TLS_Client");
            const x509Cert = convertStringToX509Cert(certString);
            hash = fingerPrint(x509Cert).fp;
            cert = convertX509CertToMimeUrlEncodedString(x509Cert);
            console.log(cert)
            console.log(hash)
        } catch (error) {
            setMessage(`Failed to get certificate: ${error.message}`);
            setIsSubmitting(false);  // Re-enable the button
            return;
        }

        const xmlPayload = `<?xml version="1.0" encoding="UTF-8"?>
        <ns7:oadrPayload xmlns="http://www.w3.org/2000/09/xmldsig#"
          xmlns:ns10="http://www.opengis.net/gml/3.2"
          xmlns:ns11="http://docs.oasis-open.org/ns/emix/2011/06/siscale"
          xmlns:ns12="http://www.w3.org/2009/xmldsig11#"
          xmlns:ns13="urn:un:unece:uncefact:codelist:standard:5:ISO42173A:2010-04-07"
          xmlns:ns2="http://docs.oasis-open.org/ns/energyinterop/201110"
          xmlns:ns3="http://docs.oasis-open.org/ns/energyinterop/201110/payloads"
          xmlns:ns4="http://docs.oasis-open.org/ns/emix/2011/06"
          xmlns:ns5="urn:ietf:params:xml:ns:icalendar-2.0"
          xmlns:ns6="urn:ietf:params:xml:ns:icalendar-2.0:stream"
          xmlns:ns7="http://openadr.org/oadr-2.0b/2012/07"
          xmlns:ns8="http://www.w3.org/2005/Atom" xmlns:ns9="http://docs.oasis-open.org/ns/emix/2011/06/power">
          <ns7:oadrSignedObject>
            <ns7:oadrCreatedEvent ns2:schemaVersion="2.0b">
              <ns3:eiCreatedEvent>
                <ns2:eiResponse>
                  <ns2:responseCode>200</ns2:responseCode>
                  <ns2:responseDescription>Response Description</ns2:responseDescription>
                  <ns3:requestID/>
                </ns2:eiResponse>
                <ns2:eventResponses>
                  <ns2:eventResponse>
                    <ns2:responseCode>200</ns2:responseCode>
                    <ns3:requestID>1001</ns3:requestID>
                    <ns2:qualifiedEventID>
                      <ns2:eventID>${eventId}</ns2:eventID>
                      <ns2:modificationNumber>${modificationId}</ns2:modificationNumber>
                    </ns2:qualifiedEventID>
                    <ns2:optType>${optIn ? "optIn" : "optOut"}</ns2:optType>
                  </ns2:eventResponse>
                </ns2:eventResponses>
                <ns2:venID>${venId}</ns2:venID>
              </ns3:eiCreatedEvent>
            </ns7:oadrCreatedEvent>
          </ns7:oadrSignedObject>
        </ns7:oadrPayload>`;

        const headers = {
            'Content-Type': 'application/xml',
            'Authorization': `Bearer ${token}`,
            'x-forwarded-client-cert': `Hash=${hash};Cert=${cert}`
        };

        try {
            console.log(API_BASE_URL)
            const response = await fetch(`${API_BASE_URL}/open_adr_service/VTN/OpenADR2/Simple/2.0b/EiEvent`, {
                method: 'POST',
                headers: headers,
                body: xmlPayload
            });

            if (response.ok) {
                const data = await response.text();
                setMessage(`Created Event sent successfully: ${data}`);
            } else {
                const errorText = await response.text();
                setMessage(`Failed to send Created Event: ${errorText}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);  // Re-enable the button
        }
    };

    return (
        <div className="form-container">
        <h2>Send Create Event OADR Message</h2>

            {loading ? (
                <p>Please wait...</p>
            ) : (
                <form onSubmit={sendCreatedEvent} className="form">
                    <div className="form-group">
                        <label>VEN ID:</label>
                        <input type="text" value={venId} readOnly className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Event ID:</label>
                        <input type="text" value={eventId} onChange={(e) => setEventId(e.target.value)} required className="form-control" />
                    </div>
                    <button type="submit" className="btn" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send Created Event'}
                    </button>
                </form>
            )}
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default SendCreatedEvent;
