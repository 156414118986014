import React, { useState } from 'react';

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const inputGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px'
};

const labelStyle = {
    marginBottom: '5px',
    fontWeight: 'bold'
};

const inputStyle = {
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc'
};

const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer'
};

const hrStyle = {
    margin: '20px 0',
    border: 'none',
    borderTop: '1px solid #ccc'
};

const messageContainerStyle = {
    position: 'fixed',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '600px',
    zIndex: 1000
};

const messageStyle = {
    margin: '10px 0',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center'
};

const successStyle = {
    ...messageStyle,
    backgroundColor: '#dff0d8',
    color: '#3c763d'
};

const errorStyle = {
    ...messageStyle,
    backgroundColor: '#f2dede',
    color: '#a94442'
};

function CreateMeteringPoint() {
    const [mpId, setMpId] = useState('');
    const [capacity, setCapacity] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [postcode, setPostcode] = useState('');
    const [ags, setAgs] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [city, setCity] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


    const handleSubmit = async (e) => {
        e.preventDefault();

        const meteringPointData = {
            mp_id_by_dso: mpId,
            grid_connection_capacity: parseFloat(capacity),
            start_time: new Date(startTime).toISOString(),
            end_time: new Date(endTime).toISOString(),
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            postcode: postcode,
            ags: ags,
            street: street,
            house_number: houseNumber,
            city: city,
        };

        const token = localStorage.getItem('kc_token');
        try {
            const response = await fetch(`${API_BASE_URL}/metering_point`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(meteringPointData)
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Metering Point created successfully');
                setErrorMessage('');
            } else {
                setErrorMessage(`Failed to create Metering Point: ${data.message || 'Unknown error'}`);
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage(`Failed to create Metering Point: ${error.message}`);
            setSuccessMessage('');
        }
    };

    return (
        <div>
            <h2>Create Metering Point</h2>
            <hr style={hrStyle} />
            <form onSubmit={handleSubmit} style={formStyle}>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>MP ID by DSO:</label>
                    <input type="text" value={mpId} onChange={(e) => setMpId(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Grid Connection Capacity:</label>
                    <input type="number" value={capacity} onChange={(e) => setCapacity(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Start Time:</label>
                    <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>End Time:</label>
                    <input type="datetime-local" value={endTime} onChange={(e) => setEndTime(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Latitude:</label>
                    <input type="number" step="0.000001" value={latitude} onChange={(e) => setLatitude(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Longitude:</label>
                    <input type="number" step="0.000001" value={longitude} onChange={(e) => setLongitude(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Postcode:</label>
                    <input type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>AGS:</label>
                    <input type="text" value={ags} onChange={(e) => setAgs(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>Street:</label>
                    <input type="text" value={street} onChange={(e) => setStreet(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>House Number:</label>
                    <input type="text" value={houseNumber} onChange={(e) => setHouseNumber(e.target.value)} required style={inputStyle} />
                </div>
                <div style={inputGroupStyle}>
                    <label style={labelStyle}>City:</label>
                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required style={inputStyle} />
                </div>
                <button type="submit" style={buttonStyle}>Create Metering Point</button>
            </form>
            <div style={messageContainerStyle}>
                {successMessage && <div style={successStyle}>{successMessage}</div>}
                {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
            </div>
        </div>
    );
}

export default CreateMeteringPoint;
